<template>
  <b-sidebar id="nav-menu" right shadow no-header backdrop>

    <div class="d-flex">
      <div class="my-2" id="close-button" v-b-toggle.nav-menu>
        &times;
      </div>
      <SelectLanguage/>
    </div>
      <!-- <div class="w-100"><b-button class="m-auto" variant="primary" @click="csvDown">hotel list csv down</b-button></div> -->

    <!-- <h2 class="icon icon-lab">Sidebar</h2> -->
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a v-if="isLanding" class="nav-link" href="/"><i class="fas fa-home mr-2"></i> {{$t('menu.home')}}</a>
        <router-link v-else class="nav-link" to="/"><i class="fas fa-home mr-2"></i> {{$t('menu.home')}}</router-link>
      </li>
      <li class="nav-item">
        <a class="nav-link navbar-link" v-b-toggle.itemLink1> <i class="fas fas fa-swimmer mr-2"></i> {{$t('menu.dest-list')}} </a>
        <b-collapse id="itemLink1" is-nav>
          <b-navbar-nav class="ml-auto">
            <li class="list-group-item" v-for="(dest,inx) in destinationList" :key="inx">
              <a v-if="isLanding" :href="`/hotels-page/?city=${dest.code}`">{{dest.name}}</a>
              <router-link v-else :to="`/hotels-page/?city=${dest.code}`">{{dest.name}}</router-link>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </li>
      <li class="nav-item">
        <a class="nav-link navbar-link" v-b-toggle.itemLink2> <i class="fas fas fa-swimmer mr-2"></i> {{$t('menu.lp-list')}} </a>
        <b-collapse id="itemLink2" is-nav>
          <b-navbar-nav class="ml-auto">
            <li class="list-group-item" v-for="(dest,inx) in destDataForLp" :key="inx">
              <a :href="`/?destination=${dest}`">{{getDestinationName(dest)}}</a>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </li>
      <li class="nav-item">
        <a class="nav-link navbar-link" v-b-toggle.itemLink3> <i class="fas fas fa-swimmer mr-2"></i> {{$t('menu.lp-chain-list')}} </a>
        <b-collapse id="itemLink3" is-nav>
          <b-navbar-nav class="ml-auto">
            <li class="list-group-item" v-for="(chain,inx) in activeChain" :key="inx">
              <a :href="`/?chain=${chain.name}`">{{chain.name}}</a>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </li>
      <li class="nav-item">
        <a class="nav-link navbar-link" v-b-toggle.itemLink4> <i class="fas fas fa-swimmer mr-2"></i> {{$t('menu.lp-category-list')}} </a>
        <b-collapse id="itemLink4" is-nav>
          <b-navbar-nav class="ml-auto">
            <li class="list-group-item" v-for="(category,inx) in categoryList" :key="inx">
              <a v-if="isLanding" :href="`/?dealType=${category.name}`">{{category.name}}</a>
              <router-link v-else :to="`/?dealType=${category.name}`">{{category.name}}</router-link>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </li>

      <li class="nav-item" v-for="(subType, inx_subType) in getStatictPageSubtypes" :key="inx_subType">
        <a class="nav-link navbar-link" v-b-toggle="'subtypeLink'+inx_subType">
          <i class="fas fas fa-swimmer mr-2"></i>
          {{subTypeName(subType)}}
        </a>
        <b-collapse :id="'subtypeLink'+inx_subType" is-nav>
          <b-navbar-nav class="ml-auto">
            <li class="list-group-item" v-for="(item, inx) in getItemListWithSubType(subType[0])" :key="'S'+inx">
              <a v-if="isLanding" :href="`/${item[0]}`"> {{item[1]}}</a>
              <router-link v-else :to="`/${item[0]}`"> {{item[1]}}</router-link>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </li>
    </ul>
  </b-sidebar>
</template>

<script>
import { mapGetters } from 'vuex';
import { BSidebar, VBToggle, BCollapse, BNavbarNav } from 'bootstrap-vue';

export default {
  name: 'NavMenu',
  components: {
    // BButton,
    BSidebar,
    BCollapse,
    BNavbarNav,
    SelectLanguage: () => import('@/components/atoms/SelectLanguage'),
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      isLanding: 'GET_IS_LANDING_PAGE',
      destinationList: 'GET_DOMESTIC_DESTINATION',
      hotelList: 'GET_DOMESTIC_HOTELS',
      hotelAllInfo: 'GET_HOTEL_ALL_INFO',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      destDataForLp: 'GET_DEST_DATA_FOR_LP',
      chainList: 'GET_HOTEL_CHAIN_TABLE',
      categoryList: 'GET_HOTEL_CATEGORY',
      activeChainIdList: 'GET_HOTEL_ACTIVE_CHAIN',
      staticPageList: 'GET_FOOTER_PAGES_LIST',
      staticPageSubtypeList: 'GET_FOOTER_CATEGORY_LIST',
    }),
    csvContent() {
      const { hotelList } = this;
      if (!hotelList) return null;
      const rows = [['hotel name', 'url']];
      hotelList.data.forEach((item) => {
        // rows.push([JSON.parse(`"${this.toUnicode(item.hotelName)} ${this.toUnicode(item.destinationName)}"`), `https://www.flyingcarpet.co.il/hotel-info/?city=${item.mainCityCode}&hotelCode=${item.hotelExternalCode}`]);
        rows.push([`${item.hotelName} ${item.destinationName}`, `https://www.flyingcarpet.co.il/hotel-info/?city=${item.mainCityCode}&hotelCode=${item.hotelExternalCode}`]);
      });
      return `data:text/csv;charset=utf-8, ${rows.map((e) => e.join(',')).join('\n')}`;
    },
    activeChain() {
      return this.chainList.filter((chain) => this.activeChainIdList.indexOf(chain.id) >= 0);
    },
    getStatictPageSubtypes() {
      const list = [];
      if (this.staticPageSubtypeList) {
        this.staticPageSubtypeList.forEach((item) => {
          const subItem = [];
          subItem[0] = item.code;
          subItem[1] = item.nameTranslationEng;
          subItem[2] = item.nameTranslationHeb;
          list.push(subItem);
        });
      }
      return list;
    },
    getStaticPages() {
      const list = [];
      if (this.staticPageList) {
        Object.entries(this.staticPageList).forEach((item) => {
          const subItem = [];
          subItem[0] = item[1].slug;
          subItem[1] = item[1].title;
          subItem[2] = item[1].category;
          list.push(subItem);
        });
      }
      return list;
    },
  },
  mounted() {
  },
  data() {
    return {
    };
  },
  async created() {
    await this.$store.dispatch('FETCH_DEST_DATA_FOR_LP');
  },
  methods: {
    csvDown() {
      const encode = encodeURI(this.csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encode);
      link.setAttribute('download', 'sitemap.csv');
      document.body.appendChild(link);

      link.click();
    },
    toUnicode(str) {
      let result = '';
      for (let i = 0; i < str.length; i += 1) {
        result += `\\u${(`000${str[i].charCodeAt(0).toString(16)}`).slice(-4)}`;
      }
      return result;
    },
    getDestinationName(dest) {
      return this.destinationList.find((item) => (item.code === dest))?.name || '';
    },
    getItemListWithSubType(subtype) {
      return this.getStaticPages.filter((item) => (item[2] === subtype));
    },
    subTypeName(item) {
      if (this.lang === 'en') {
        return item[1];
      } else {
        return item[2];
      }
    },
  },
};
</script>
<style scoped>
  #nav-menu #close-button{
    font-size: 40px;
    line-height: 28px;
    margin: 0px 27px;
  }
  #nav-menu ul li a {
    display: block;
    padding: 1em 1em 1em 1.2em;
    outline: none;
    border-bottom: 1px dashed rgb(158 156 156 / 20%);
    text-transform: uppercase;
    text-shadow: 0 0 1px rgba(255,255,255,0.1);
    letter-spacing: 1px;
    font-weight: 400;
    -webkit-transition: background 0.3s, box-shadow 0.3s;
    transition: background 0.3s, box-shadow 0.3s;
  }
  #nav-menu ul li .nav-link {
    padding-right: 1em;
    padding-left: 1em;
    align-items: center;
    direction: rtl;
    background-color: white;
  }
  .navbar-nav{
    padding-right: 0;
  }
  .navbar-link.collapsed:after {
    content: "\f107";
  }
  .navbar-link:after {
    content: "\f106";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    float: left;
  }
</style>
<style>
  .b-sidebar.b-sidebar-right{
    transition: 0.5s;
  }
 #nav-menu{
   height: 100vh;
 }
 @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .b-sidebar-outer{
    z-index: 1050;
  }
  .b-sidebar > .b-sidebar-body {
    background-color: white;
  }
  .b-sidebar-backdrop {
    position: absolute;
  }
}
</style>
